@import "@/styles/allVariables";

.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: $top-nav-height;
}

.profile-dropdown {
  margin-left: auto;

  > a.dropdown-toggle {
    color: white;
  }
  >a.dropdown-toggle::after {
    display: none;
  }
}


.profile-dropdown-button {
  border-radius: 2rem;
  padding: 0.25rem 0.75rem;
  border-width: 1.5px;

  @include media-breakpoint-up(sm) {
    padding: 0.5rem 1.5rem;
  }

  >span {
    margin-right: 0.35em;
  }
}

.top-nav {
  background-image: linear-gradient(to right, #fff 2%, #00DBD0 55%, #008BC2);
}

.top-nav-logo {
  width: 175px;
  padding: 0 0.5rem;

  @include media-breakpoint-up(sm) {
    width: 296px;
  }

  img {
    width: 100%;
  }
}