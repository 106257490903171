@import "@/styles/allVariables";

.content-container {
  padding-left: 0;
  padding-top: $top-nav-height;

  @include media-breakpoint-up(lg) {
    padding-left: $side-nav-width;
  }
}
