.fade-in {
  animation: fadeIn 0.4s;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-out {
  animation: fadeOut 0.4s;
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; } 
}