// Include all of the custom global variables here
// These variables can include bootstrap overrides.
// For example to override the primary color you can set
$primary: #208bc1;
$secondary: #4ec2c0;
// $secondary: #495057;
$light-blue-1: #81BAE8;
$light-blue-2: #BCE0FD;
$light-blue-3: #F1F9FF;
$light-gray: #6c757d;
$offwhite: #D3D8DC;
$title-blue: #2699FB;
// @/styles/bootstrap.scss will then merge the custom variables properly with the bootstrap scss
$card-border-radius: 0;
$card-border-width: 0;
$popover-border-radius: 0;


$app-background: #e5e5e5;
$side-nav-width: 320px;
$top-nav-height: 82px;