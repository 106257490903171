@import "@/styles/allVariables";

html, body {
  background-color: $app-background;
  font-family: 'Poppins', sans-serif;
}

#splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, white, #9ad7db, #4ac0bf, #3b9bc7);
}
#splash-screen>img {
  width: 240px;
}

.App {
  // ensures bottom mobile nav does not cover content when scrolling
  padding-bottom: 80px;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.app-container {
  max-width: 900px;
}

.App-link {
  color: rgb(112, 76, 182);
}

a {
  text-decoration: none;
  color: inherit;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.page-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.highlight-drop-area {
  background-color: rgba(0, 85, 145, 0.07);
  border: 1px solid #ccc !important;
  border-radius: 0.25rem;
}
.highlight-drop-area-no-border {
  background-color: rgba(0, 85, 145, 0.07);
}

.text-wrap {
  white-space: normal !important;
  overflow-wrap: anywhere;
}

.app-card-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 0px;
  background-color: #fff;
  padding: 5px 2px;
}

// make z-index of offcanvas larger than modal so offcanvas will overlap modal
.offcanvas-backdrop, .offcanvas {
  z-index: 1060;
}

.no-style-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover, &:active, &:focus{
    background: none;
    color: inherit;
  }
}

.floating-action-button {
  border-radius: 25px;
  width: 50px;
  height: 50px;

  >svg {
    font-size: 28px;
  }
}

.offcanvas-bottom-right {
  position: fixed;
  bottom: 150px;
  right: 22px !important;
  top: auto !important;
  width: auto !important;
  border: none !important;
  background: transparent !important;

  @include media-breakpoint-up(lg) {
    bottom: 70px;
  }
}

.offcanvas-bottom-right-button {
  width: 44px;
  height: 44px;
  border-radius: 20px;

  >svg {
    font-size: 16px;
  }
}

.fixed-bottom-right {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 5;

  @include media-breakpoint-up(lg) {
    bottom: 20px;
  }
}

// Custom Helpers
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-underline {
   text-decoration: underline;
}

.text-sm {
  font-size: .75em;
}

.text-light-blue {
  color: $title-blue;
}

.border-transparent {
  border-color: transparent;
}

.has-fab {
  padding-bottom: 75px;
}

.disabled {
  color: #F2F2F2;
  cursor: 'not-allowed';
}

.text-dark-blue {
  color: #0e3048;
}

.blue-gradient {
  background-image: linear-gradient(to right, #4cc1bf 55%, #3790c4);
}

.btn-primary {
  @include button-variant(
    $background: $primary,
    $border: $primary,
    $color: #fff,
    $hover-color:#fff,
    $active-color: #fff
  );
}
.btn-outline-primary {
  @include button-outline-variant(
    $color: $primary,
    $color-hover: #fff,
    $active-color: #fff
  );
}
.btn-secondary {
  @include button-variant(
    $background: $secondary,
    $border: $secondary,
    $color: #fff,
    $hover-color:#fff,
    $active-color: #ddd
  );
}
.btn-outline-secondary {
  @include button-outline-variant(
    $color: $secondary,
    $color-hover: #fff,
    $active-color: #fff
  );
}

.dropdown>button::after {
  display: none;
}

.key-value-table {
  tr>td:first-child {
    padding-right: 1rem;
    font-weight: bold;
  }
  tr>td {
    padding-top: 0.5rem;
    vertical-align: top;
  }
  tr:first-child>td {
    padding-top: 0;
  }
}

.modal-content {
  border-radius: 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.list-modal {
  background-color: $app-background;

  .modal-header {
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  .list-group {
    border-radius: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .list-group-item {
    border-left: 0;
    border-right: 0;

    svg[class*="fa-"]{
      color: $primary;
      text-align: center;
      width: 20px;
    }

    &.active {
      svg[class*="fa-"]{
        color: white;
      }
    }
  }
}

.list-offcanvas {
  border-radius: 0;
  background-color: $app-background;
  height: auto !important;

  .offcanvas-header {
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .offcanvas-body {
    padding-left: 0;
    padding-right: 0;
  }

  .list-group {
    border-radius: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .list-group-item {
    border-left: 0;
    border-right: 0;

    svg[class*="fa-"]{
      color: $primary;
      text-align: center;
      width: 20px;
    }

    &.active {
      svg[class*="fa-"]{
        color: white;
      }
    }
  }
}

.sticky-modal-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1055;
}

.sticky-bottom {
  // account for mobile nav
  bottom: 72px;

  @include media-breakpoint-up(lg) {
    bottom: 0;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
